import style from "./PortfolioCard.module.css";

function PorfolioCard(props) {
  const handleNewWindow = () => {
    if (props.data.name === "Garma App") {
      window.open(props.data.live, "name", "width=375,height=812");
    } else {
      window.open(props.data.live);
    }
  };
  return (
    <div className={style.tile} key={props.data.name}>
      <h1 className={style.title}>{props.data.name}</h1>
      <img
        className={style.img}
        alt={"gif " + props.data.name}
        src={props.data.img}
      ></img>
      <div className={style.linkBox}>
        <a href={props.data.github} target="_blank" rel="noreferrer">
          <i id={style.icon} className="fab fa-github"></i>
        </a>
        <span onClick={handleNewWindow}>
          <i id={style.icon} className="fas fa-broadcast-tower"></i>
        </span>
      </div>
      <div className={style.tagBox}>
        {props.data.tags.map((tag) => {
          return (
            <div className={style.tag} key={tag + props.data.name}>
              {tag}
            </div>
          );
        })}
      </div>
      {/*       <p className={style.desc}>{props.data.desc}</p>
       */}{" "}
    </div>
  );
}
export default PorfolioCard;
