import style from "./Skills.module.css";
import { motion } from "framer-motion";
import { container, item } from "./animations";
import { skills } from "./skills/skills";
function Skills() {
  return (
    <div className={style.card}>
      <p className={style.title}>{"{Skills}"}</p>
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className={style.skillBox}
      >
        {skills.stack.map((skill) => {
          return (
            <motion.i
              id={style.icon}
              variants={item}
              className={skill}
            ></motion.i>
          );
        })}
      </motion.div>
      <p className={style.title}>{"{ToolBox}"}</p>
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className={style.toolBox}
      >
        {skills.toolbox.map((tool) => {
          return (
            <motion.i
              id={style.icon}
              variants={item}
              className={tool}
            ></motion.i>
          );
        })}
      </motion.div>
    </div>
  );
}

export default Skills;
