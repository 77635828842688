import { useState } from "react";
import style from "./Contact.module.css";
import { motion } from "framer-motion";
import {
  container,
  item,
  loadingBox,
  loadingCricTrans,
  loadingCricVar,
} from "./animations";
import emailjs from "emailjs-com";
const service = process.env.REACT_APP_EMAILJS_SERVICE;
const id = process.env.REACT_APP_EMAILJS_ID;
const template = process.env.REACT_APP_EMAILJS_TEMP;

function Contact() {
  const [sent, setSent] = useState("form");
  const handleSubmit = (event) => {
    event.preventDefault();
    setSent("sending");
    emailjs.sendForm(service, template, event.target, id).then(
      (result) => {
        setSent("complete");
        console.log(result.text);
      },
      (error) => {
        setSent("bad");
        console.log(error.text);
      }
    );
  };
  return (
    <div className={style.card}>
      {sent === "form" && (
        <motion.form
          variants={container}
          initial="hidden"
          animate="visible"
          onSubmit={handleSubmit}
          className={style.form}
        >
          <motion.div variants={item} className={style.triangle}>
            ▶
            <input
              required
              className={style.input}
              type="name"
              name="user_name"
              autoComplete="name"
              placeholder="Name"
            ></input>
          </motion.div>

          <motion.div variants={item} className={style.triangle}>
            ▶
            <input
              required
              className={style.input}
              type="email"
              name="user_email"
              autoComplete="email"
              placeholder="E-mail"
            ></input>
          </motion.div>

          <motion.div variants={item} className={style.triangle}>
            ▶
            <input
              required
              className={style.input}
              type="text"
              name="subject"
              placeholder="Subject"
            ></input>
          </motion.div>
          <motion.textarea
            variants={item}
            required
            id={style.text}
            className={style.input}
            name="message"
            type="text"
            placeholder="Message"
          ></motion.textarea>

          <motion.button variants={item} className={style.button}>
            Send
          </motion.button>
        </motion.form>
      )}
      {sent === "complete" && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className={style.sent}
        >
          <motion.i id={style.icon} className="far fa-thumbs-up"></motion.i>
          <motion.div variants={item} className={style.thank}>
            Thank you for your message! I'll come back to you very soon!
          </motion.div>
        </motion.div>
      )}
      {sent === "sending" && (
        <motion.div
          className={style.waitBox}
          variants={loadingBox}
          initial="start"
          animate="end"
        >
          <motion.div
            className={style.circle}
            variants={loadingCricVar}
            transition={loadingCricTrans}
          ></motion.div>
          <motion.div
            className={style.circle}
            variants={loadingCricVar}
            transition={loadingCricTrans}
          ></motion.div>
          <motion.div
            className={style.circle}
            variants={loadingCricVar}
            transition={loadingCricTrans}
          ></motion.div>
        </motion.div>
      )}
      {sent === "bad" && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className={style.sent}
        >
          <motion.i
            id={style.icon}
            className="fas fa-exclamation-triangle"
          ></motion.i>

          <motion.div variants={item} className={style.thank}>
            Failed to send the form, please try again !
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}
export default Contact;
