export const skills = {
  stack: [
    "devicon-typescript-plain",
    "devicon-react-original-wordmark",
    "devicon-rails-plain-wordmark",
    "devicon-php-plain",
    "devicon-laravel-plain-wordmark",
    "devicon-mysql-plain-wordmark",
    "devicon-postgresql-plain-wordmark",
  ],
  toolbox: [
    "devicon-git-plain-wordmark",
    "devicon-yarn-plain-wordmark",
    "devicon-docker-plain-wordmark",
  ],
};
