import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import style from "./About.module.css";
import { text } from "./aboutText";
function About() {
  const [scroll, setScroll] = useState(0);
  const [view, setView] = useState(text[0]);
  useEffect(() => {
    setView(text[scroll]);
  }, [scroll]);

  const handleClick = () => {
    if (scroll < text.length - 1) {
      setScroll(scroll + 1);
    } else {
      setScroll(0);
    }
  };
  return (
    <div className={style.card}>
      <div className={style.box}>
        <motion.p animation={{ scale: 2 }}>{view}</motion.p>
        <span className={style.moreButton} onClick={handleClick}>
          {scroll === text.length - 1 ? "End" : "More"}
        </span>
      </div>
    </div>
  );
}
export default About;
