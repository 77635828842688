//import { useContext } from "react";
//import { store } from "../store";
import style from "./Main.module.css";
import { motion } from "framer-motion";

function Main(props) {
  //const { setCommand } = useContext(store);

  return (
    <div className={style.card}>
      <motion.div className={style.title}>
        Hi, I'm <span className={style.underline}>{"Andrea"}</span>
      </motion.div>
      <motion.div className={style.text}>
        <div className={style.triangleLeft}></div>
        <span className={style.big}>{"Full-Stack Dev"}</span>
        <div className={style.triangleRight}></div>
      </motion.div>
    </div>
  );
}

export default Main;
