export const portfolioContent = [
  {
    name: "Garma App",
    desc: "Web app connected to Movie database to search for moovies and tv shows",
    live: "https://garma-mobile.herokuapp.com/",
    github: "https://github.com/andreamazzatxt/garma",
    img: "./garma.gif",
    tags: [
      "#Ruby on Rails",
      "#Barcodescanner",
      "#PostgreSQL",
      "#JS",
      "#Kimurai gem",
      "#PWA",
    ],
  },
  {
    name: "Moovier",
    desc: "Web app connected to Movie database to search for moovies and tv shows",
    live: "https://moovier.surge.sh/",
    github: "https://github.com/andreamazzatxt/moovier",
    img: "./moovier.gif",
    tags: [
      "#React",
      "#API",
      "#FramerMotion",
      "#JavaScript",
      "#Responsive",
      "#SPA",
    ],
  },
  {
    name: "Century 21",
    desc: "Gym Landing Page",
    live: "https://century21.surge.sh",
    github: "https://github.com/andreamazzatxt/century21",
    img: "./century21.gif",
    tags: [
      "#React",
      "#Google Map API",
      "#React Spring",
      "#JavaScript",
      "#Responsive",
      "#SPA",
    ],
  },
];
