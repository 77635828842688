import { motion } from "framer-motion";
import { LoadingVar, LoadingTrans, pathTRANS, pathVAR } from "./animations";
import style from "./Loading.module.css";

function Loading() {
  return (
    <motion.div
      variants={LoadingVar}
      initial="start"
      animate="end"
      transition={LoadingTrans}
      className={style.container}
    >
      <motion.div
        variants={pathVAR}
        initial="start"
        animate="end"
        transition={pathTRANS}
        className={style.element}
      ></motion.div>
      <motion.div
        variants={pathVAR}
        initial="start"
        animate="end"
        transition={pathTRANS}
        className={style.element}
      ></motion.div>
      <motion.div
        variants={pathVAR}
        initial="start"
        animate="end"
        transition={pathTRANS}
        className={style.element}
      ></motion.div>
    </motion.div>
  );
}
export default Loading;
