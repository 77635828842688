import style from "./Portfolio.module.css";
import { portfolioContent } from "./portfolio_content";
import PorfolioCard from "./PortfolioCard";

function Portfolio() {
  return (
    <div className={style.card}>
      <div className={style.box}>
        {portfolioContent.map((element, index) => {
          return <PorfolioCard data={element} key={element.name + index} />;
        })}
      </div>
    </div>
  );
}
export default Portfolio;
