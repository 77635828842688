import style from "./SocialBox.module.css";

function SocialBox() {
  return (
    <div className={style.box}>
      <a
        href="https://github.com/andreamazzatxt"
        target="_blank"
        rel="noreferrer"
      >
        <i id={style.icon} className="fab fa-github-square"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/andreamazza1/"
        target="_blank"
        rel="noreferrer"
      >
        <i id={style.icon} className="fab fa-linkedin"></i>
      </a>
      <a href="mailto:andreamazza89@gmail.com">
        <i id={style.icon} className="fas fa-envelope"></i>
      </a>
    </div>
  );
}

export default SocialBox;
