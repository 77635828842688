import { useContext } from "react";
import { store } from "../store";
import style from "./Nav.module.css";
import SocialBox from "./SocialBox";
import { motion } from "framer-motion";
import { swift } from "./animations";

function Nav(props) {
  const { setCommand } = useContext(store);
  const handleClick = (stat) => {
    setCommand("empty");
    setTimeout(() => {
      setCommand(stat);
    }, 500);
  };

  return (
    <div className={style.bar}>
      <motion.img
        whileHover={swift(-20)}
        className={style.avatar}
        src="avatar.png"
      ></motion.img>
      <div
        onClick={() => {
          handleClick("main");
        }}
        className={props.nav.main ? style.selectedElement : style.element}
      >
        {"Home"}
      </div>

      <div
        onClick={() => {
          handleClick("portfolio");
        }}
        className={props.nav.portfolio ? style.selectedElement : style.element}
      >
        {"Portfolio"}
      </div>
      <div
        onClick={() => {
          handleClick("skills");
        }}
        className={props.nav.skills ? style.selectedElement : style.element}
      >
        {"Skills"}
      </div>
      <div
        onClick={() => {
          handleClick("contact");
        }}
        className={props.nav.contact ? style.selectedElement : style.element}
      >
        {"Contact"}
      </div>
      <SocialBox />
    </div>
  );
}

export default Nav;
