// animate Loading Page

export const LoadingVar = {
  start: { opacity: 1 },
  end: {
    opacity: 0,
  },
};

export const LoadingTrans = {
  delay: 6,
  duration: 4,
  ease: "easeOut",
  delayChildren: 0.3,
  staggerChildren: 0.2,
};

export const pathVAR = {
  start: {
    transform: "rotate(0deg)",
    opacity: 0,
  },
  end: {
    transform: "rotate(90deg)",
    opacity: 1,
  },
};
export const pathTRANS = {
  duration: 1,
  repeat: Infinity,
  repeatType: "mirror",
  ease: "anticipate",
};
// animate avatar on hover
export const swift = (deg) => {
  return { transform: `rotate(${deg}deg)` };
};

// animate Exit

export const offScreen = (axe) => {
  if (axe === "x") {
    return {
      x: 300,
      opacity: 0,
    };
  } else {
    return {
      y: 300,
      opacity: 0,
    };
  }
};
export const onScreen = (axe) => {
  if (axe === "x") {
    return {
      x: 0,
      opacity: 1,
    };
  } else {
    return {
      y: 0,
      opacity: 1,
    };
  }
};

export const exitScreen = (axe) => {
  if (axe === "x") {
    return {
      x: -300,
      opacity: 0,
    };
  } else {
    return {
      y: -300,
      opacity: 0,
    };
  }
};

export const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
// animate sending message
export const loadingBox = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
export const loadingCricVar = {
  start: { y: "0%" },
  end: { y: "100%" },
};
export const loadingCricTrans = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "anticipate",
};
