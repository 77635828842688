import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import "./App.css";
import { exitScreen, offScreen, onScreen } from "./components/animations";
import Main from "./components/Main";
import Nav from "./components/Nav";
import About from "./components/About";
import Skills from "./components/Skills";
import Loading from "./components/Loading";
import { store } from "./store";
import BIRDS from "vanta/dist/vanta.birds.min";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

const menu = ["main", "about", "portfolio", "skills", "contact"];
function App() {
  const [loading, setLoading] = useState(true);
  const [command, setCommand] = useState("main");
  const [navigation, setNavigation] = useState({});
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  // Background animation
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color1: 0xf373e,
          colorMode: "lerp",
          birdSize: 1.4,
          wingSpan: 24.0,
          speedLimit: 3.0,
          separation: 63.0,
          cohesion: 100.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  // handle Loading Page

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  // handle navigaton
  const handleSet = (obj) => {
    setNavigation(obj);
  };
  useEffect(() => {
    let temp = {};
    menu.forEach((element) => {
      if (element === command) {
        temp[element] = true;
      } else {
        temp[element] = false;
      }
    });

    handleSet(temp);
  }, [command]);

  return (
    <store.Provider value={{ command, setCommand }}>
      {loading && <Loading />}
      <div ref={myRef}>
        <div className="App">
          <div className="container">
            <Nav nav={navigation} />
            <AnimatePresence>
              {navigation.main && (
                <motion.div
                  key="main"
                  initial={offScreen("y")}
                  animate={onScreen("y")}
                  exit={exitScreen("y")}
                >
                  <Main />
                </motion.div>
              )}
              {navigation.skills && (
                <motion.div
                  key="skills"
                  initial={offScreen("y")}
                  animate={onScreen("y")}
                  exit={exitScreen("y")}
                >
                  <Skills />
                </motion.div>
              )}
              {navigation.about && (
                <motion.div
                  key="about"
                  initial={offScreen("y")}
                  animate={onScreen("y")}
                  exit={exitScreen("y")}
                >
                  <About />
                </motion.div>
              )}
              {navigation.portfolio && (
                <motion.div
                  key="portfolio"
                  initial={offScreen("y")}
                  animate={onScreen("y")}
                  exit={exitScreen("y")}
                >
                  <Portfolio />
                </motion.div>
              )}
              {navigation.contact && (
                <motion.div
                  key="contact"
                  initial={offScreen("y")}
                  animate={onScreen("y")}
                  exit={exitScreen("y")}
                >
                  <Contact />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </store.Provider>
  );
}

export default App;
